import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CalculationOnline from "components/CalculationOnline";
import CalculationBatch from "components/CalculationBatch";
import FAQPage from "components/FAQPage";
import PlanInfoPage from "components/PlanInfoPage";
import ContactUsPage from "components/ContactUsPage";
import SideBarMenu from "components/AucaComponents/SidebarMenu";
import { selectVerticalMenu } from "store/slices/verticalMenu";
import { selectVerticalMenuExpand } from "store/slices/verticalMenuExpand";
import { SideBarElementType } from "components/AucaComponents/SidebarMenu";
import useMediaQuery from "@mui/material/useMediaQuery";
import json2mq from "json2mq";
import breakpoints from "styles/export.module.scss";
import { Info, LiveHelp, ContactPage, Tune, Calculate } from "@mui/icons-material";
import styles from "./index.module.scss";

const CalculationMenuPage: React.FC = () => {
  const menuSelection = useSelector(selectVerticalMenu);
  const expand = useSelector(selectVerticalMenuExpand);
  const { t } = useTranslation();


  // separator line order is any number, not used
  const menu: SideBarElementType[] = [
    { order: 0, name: t("sectionLabel.calculationOnline"), id: "", icon: <Tune />, type: "listElement" },
    { order: 1, name: t("sectionLabel.calculationBatch"), id: "", icon: <Calculate />, type: "listElement" },
    { order: 999, name: "", id: "", icon: "", type: "line" },
    { order: 2, name: t("sectionLabel.FAQs"), id: "", icon: <LiveHelp />, type: "listElement" },
    { order: 3, name: t("sectionLabel.information"), id: "", icon: <Info />, type: "listElement" },
    { order: 4, name: t("sectionLabel.contactUs"), id: "", icon: <ContactPage />, type: "listElement" },
    { order: 999, name: "", id: "", icon: "", type: "line" },
  ];

  const mobileView = useMediaQuery(
    json2mq({
      minWidth: breakpoints.xlg,
    })
  );

  const getPage = () => {
    switch (menuSelection) {
      case 0:
        return <CalculationOnline />;
      case 1:
        return <CalculationBatch />;
      case 2:
        return <FAQPage />;
      case 3:
        return <PlanInfoPage />;
      case 4:
        return <ContactUsPage />;
      default:
        return <CalculationOnline />;
    }
  };

  return (
    <div className={styles.CalculationMenuPage}>
      {mobileView && (
        <div className={styles.sideBar} style={{ width: expand ? "9vw" : "4vw" }}>
          <SideBarMenu menuData={menu} />
        </div>
      )}
      <div className={styles.panel} style={{ width: expand ? "91vw" : "96vw" }}>
        <div className={styles.marginTop} style={{ marginTop: mobileView ? "30px" : "120px" }}>
          {getPage()}
        </div>
      </div>
    </div>
  );
};

export default CalculationMenuPage;
