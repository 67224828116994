import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

interface Position {
  currentPosition: number;
}

const initialState: Position  = {
  currentPosition: 0,

}

export const verticalMenuSlice = createSlice({
  name: 'verticalSelection',
  initialState,
  reducers: {
    saveVerticalMenu: (state, action: PayloadAction<number>) => {
      state.currentPosition = action.payload;
    },
  },
})

export const { saveVerticalMenu } = verticalMenuSlice.actions
export const selectVerticalMenu = (state: RootState) => state.verticalMenuManagement.currentPosition
export default verticalMenuSlice.reducer