import React from "react";
import { useTranslation } from "react-i18next";
import PungoSectionCard from "pungo-ui/PungoSectionCard";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";

import WfCreation from "assets/PungoIcons/WfCreation.svg";
import WfRegistration from "assets/PungoIcons/WfRegistration.svg";
import WfCalculation from "assets/PungoIcons/WfCalculation.svg";
import WfOptimization from "assets/PungoIcons/WfOptimization.svg";
import PungoWorkflowCard from "pungo-ui/PungoWorkflowCard";
import PungoCustomizedCarousel from "pungo-ui/PungoCustomizedCarousel";

import colors from "styles/export.module.scss";
import styles from "./index.module.scss";
import breakpoints from "styles/export.module.scss";

const WorkflowSection: React.FC = () => {
  const { t } = useTranslation();

  const desktopView = useMediaQuery(
    json2mq({
      minWidth: breakpoints.lg,
    })
  );

  const workflowSteps = [
    {
      id: "workflow1",
      name: t("workflowSection.creation.header"),
      description: t("workflowSection.creation.body"),
      image: WfCreation,
      color: colors.green2,
    },
    {
      id: "workflow2",
      name: t("workflowSection.registration.header"),
      description: t("workflowSection.registration.body"),
      image: WfRegistration,
      color: colors.green3,
    },
    {
      id: "workflow3",
      name: t("workflowSection.calculation.header"),
      description: t("workflowSection.calculation.body"),
      image: WfCalculation,
      color: colors.green4,
    },
    {
      id: "workflow4",
      name: t("workflowSection.optimization.header"),
      description: t("workflowSection.optimization.body"),
      image: WfOptimization,
      color: colors.blue2,
    },
  ];

  const getWorkflowSection = () =>
    workflowSteps.map(({ id, name, description, image, color }) => (
      <div key={id} className={styles.cardContainer}>
        <PungoWorkflowCard
          key={name}
          image={image}
          title={name}
          description={description}
          color={color}
        />
      </div>
    ));

  return (
    <PungoSectionCard
      title={t("sectionLabel.workflow")}
      description={`${t("sectionContent.workflow")}`}
      id="workflow"
    >
      <div className={styles.mainContainer}>
        <div className={styles.container}>
          {desktopView ? (
            getWorkflowSection()
          ) : (
            <div className={styles.carouselContainer}>
              <PungoCustomizedCarousel
                slides={getWorkflowSection()}
                height={365}
              />
            </div>
          )}
        </div>
      </div>
    </PungoSectionCard>
  );
};

export default WorkflowSection;
