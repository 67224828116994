import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useAppDispatch } from "app/hooks";
import { getCalculateEnergySources } from "store/actions/planActions";
import { selectSurveyStatus, selectUserRole, selectUserName } from "store/slices/auth";
import DataInputSection from "components/AucaComponents/DataInputSection";
import ResultsSection from "components/AucaComponents/ResultsSection";
import FooterOnePageSection from "components/AucaComponents/FooterOnePageSection";
import PungoAccordion from "pungo-ui/PungoAccordion";
import PungoSelectLanguage from "pungo-ui/PungoSelectLanguage";
import useMediaQuery from "@mui/material/useMediaQuery";
import json2mq from "json2mq";
import breakpoints from "styles/export.module.scss";
import styles from "./index.module.scss";

const CalculationOnline: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [renderPage, setRenderPage] = useState(false);
  const isSurveyCompleted = useSelector(selectSurveyStatus);
  const isLogged = useSelector(selectUserName);
  const userRol = useSelector(selectUserRole)[0];
  
  const mobileViewForVideo = useMediaQuery(
    json2mq({
      minWidth: breakpoints.md,
    })
  );

  const getWhatIs = () => (
    <div className={styles.planDescription}>
      <div className={styles.text}>{t("CalculatorOnline.information.txt1")}</div>
    </div>
  );

  const getHowTo = () => (
    <div className={styles.planInstructions}>
      <div className={styles.text}>
        <Trans>{t("DemoPage.InstructionsSection.steps")}</Trans>
      </div>

      <div className={styles.video}>
        <ReactPlayer
          url="https://d2ay24nheu7ro1.cloudfront.net/video_howto_plan_free_es_v2.mp4"
          width={!mobileViewForVideo ? 350 : 640}
          height={!mobileViewForVideo ? 197 : 360}
          light="https://d2ay24nheu7ro1.cloudfront.net/image_video_howto_plan_free_es_v2.png"
          controls
        />
      </div>
    </div>
  );

  useEffect(() => {
    const plansAccessGrantArray = ["admin", "free_plan", "pro_plan"];

    if (!isLogged) {
      navigate("/login");
    } else if (plansAccessGrantArray.indexOf(userRol) < 0) {
      navigate("/login");
    } else if (!isSurveyCompleted && !(userRol === "company_user") && !(userRol === "company_admin") && !(userRol === "admin")) {
      navigate("/survey");
    } else {
      setRenderPage(true);
      window.scrollTo(0, 0);
      dispatch(getCalculateEnergySources());
    }
  }, [navigate, userRol, isLogged, isSurveyCompleted, dispatch]);

  return (
    <div className={styles.CalculationOnline}>
        {renderPage && (
          <section className={styles.container}>
            <div className={styles.title}>{t("CalculatorOnline.title")}</div>
            <div className={styles.accordion}>
              <PungoAccordion title={t("PlanSelect.whatIs")} content={getWhatIs()} noborder={true} />
              <PungoAccordion title={t("PlanSelect.howTo")} content={getHowTo()} noborder={true} />
            </div>
            <DataInputSection />
            <ResultsSection />
            <FooterOnePageSection />
            <PungoSelectLanguage />
          </section>
        )}
      </div>
  );
};

export default CalculationOnline;
