// ****************************************************************************************
//  Auca Component
//  Presents plan by transaction pricing
//
//  Based on pungo components PungoPlanByTransactionGroup and PungoPlanByTransactionCard
// ****************************************************************************************

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "app/hooks";
import { selectUserName } from "store/slices/auth";
import { subscribePro, subscribeSimple } from "store/actions/planActions";
import PungoSectionCard from "pungo-ui/PungoSectionCard";
import PungoPlanByTransactionGroup from "pungo-ui/PungoPlanByTransactionGroup";
import classNames from "classnames";
import styles from "./index.module.scss";

interface PungoPlanByTransactionCardProps {
  id: string;
  name: string;
  description: string;
  cost: string;
  commentToCost: string;
  frequency: string;
  link: string;
  benefits: string[];
}

const PlanByTransactionSection: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLogged = useSelector(selectUserName);

  const [plan, setPlan] = useState("monthly");
  const period = "Mensual";
  
  const handleGoToDemo = (e: any) => {
    switch (e.id){
      case "Plan2":
        if (!isLogged) {
          navigate("/signup");
        } else {
          return dispatch(subscribeSimple());
        }
        return;

      case "Plan3":
        if (!isLogged) {
          navigate("/signup");
        } else {
          return dispatch(subscribePro());
        }
        return;

      case "Plan4":
        return navigate("/contactus");
    }
  };

  const handlePlanChange = (newPlan: string) => {
    setPlan(newPlan);
  };

  const dataForPlans: PungoPlanByTransactionCardProps[] = [
    {
      id: "Plan2",
      name: `${t("planByTransaction.Plan2.name")}`,
      description: `${t("planByTransaction.Plan2.description")}`,
      cost: plan === "monthly" ? "49":"44",
      commentToCost: plan === "monthly" ? `${t("planByTransaction.Plan2.commentToCost.byMonth")}` : `${t("planByTransaction.Plan2.commentToCost.byYear")}`,
      frequency: plan === "monthly" ? `${t("planByTransaction.Plan2.frequency.byMonth")}`:`${t("planByTransaction.Plan2.frequency.byYear")}`,
      link: `${t("planByTransaction.Plan2.link")}`,
      benefits: [`${t("planByTransaction.Plan2.benefits.b0")}`, `${t("planByTransaction.Plan2.benefits.b1")}`, `${t("planByTransaction.Plan2.benefits.b2")}`],
    },
    {
      id: "Plan3",
      name: `${t("planByTransaction.Plan3.name")}`,
      description: `${t("planByTransaction.Plan3.description")}`,
      cost: plan === "monthly" ? "299" : "269",
      commentToCost: plan === "monthly" ? `${t("planByTransaction.Plan3.commentToCost.byMonth")}`: `${t("planByTransaction.Plan3.commentToCost.byYear")}`,
      frequency: plan === "monthly" ? `${t("planByTransaction.Plan3.frequency.byMonth")}`:`${t("planByTransaction.Plan3.frequency.byYear")}`,
      link: `${t("planByTransaction.Plan3.link")}`,
      benefits: [`${t("planByTransaction.Plan3.benefits.b0")}`, `${t("planByTransaction.Plan3.benefits.b1")}`, `${t("planByTransaction.Plan3.benefits.b2")}`],
    },
    {
      id: "Plan4",
      name: `${t("planByTransaction.Plan4.name")}`,
      description: `${t("planByTransaction.Plan4.description")}`,
      cost: "",
      commentToCost: `${t("planByTransaction.Plan4.commentToCost")}`,
      frequency: "",
      link: `${t("planByTransaction.Plan4.link")}`,
      benefits: [`${t("planByTransaction.Plan4.benefits.b0")}`,`${t("planByTransaction.Plan4.benefits.b1")}`,`${t("planByTransaction.Plan4.benefits.b2")}`],
    },
  ];

  return (
    <>
      {/* =======================================
            billing period selector 
            ========================================*/}
      <PungoSectionCard title={`${t("planByTransaction.title")}`} description={`${t("planByTransaction.description")}`} id="plans" distribution = "block">
        <div className={styles.planSwitcher}>
          <button
            className={classNames(styles.planButton, {
              [styles.selected]: plan === "monthly",
            })}
            onClick={() => handlePlanChange("monthly")}
          >
            {`${t("planByTransaction.monthly")}`}
          </button>
          

          <button
            className={classNames(styles.planButton, {
              [styles.selected]: plan === "annual",
            })}
            onClick={() => handlePlanChange("annual")}
          >
            {`${t("planByTransaction.annual")}`}
          </button>
        </div>

        {/* ====================================================
            Call to price by transcation component with data
            =================================================*/}
        <div className={styles.container}>
          <PungoPlanByTransactionGroup data={dataForPlans} period={period} handleClick={handleGoToDemo} />
        </div>
      </PungoSectionCard>
    </>
  );
};

export default PlanByTransactionSection;
