import React, {useEffect} from "react";
import FooterOnePageSection from "components/AucaComponents/FooterOnePageSection";
import PlanByTransactionSection from "components/AucaPage/sections/PlanByTransactionSection";
import PlanTableSection from "components/AucaPage/sections/PlanTableSection";
import ContactUsSection from "components/AucaPage/sections/ContactUsSection";
import styles from "./index.module.scss";

const PlanInfoPage: React.FC = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className={styles.planInfoPage}>
        <PlanByTransactionSection />
        <PlanTableSection />
        <ContactUsSection />
        <FooterOnePageSection />
      </div>
  );
};

export default PlanInfoPage;
