import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Bar, Doughnut } from "react-chartjs-2";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";

import {
  selectProPlanResults,
  IEnergyEmissionContribution,
  IEquipmentEmissionContribution,
  IProcessEmissionContribution,
  IEnergyCostContribution,
  IEquipmentCostContribution,
  IProcessCostContribution,
} from "store/slices/plan";

import PungoDemoAccordion from "pungo-ui/PungoDemoAccordion";
import { convertDateToUTC } from "../utils";
import SelectableTabs from "../../common/SelectableTabs";

import colors from "styles/export.module.scss";
import classnames from "classnames";
import styles from "./index.module.scss";

export interface ILabelForDatasets {
  labelEs: string;
  labelEn: string;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale
);

const ContributionSection: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isSpanishLanguageSelected = i18n.language === "es";
  const proPlanResults = useSelector(selectProPlanResults);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [contributionSelectedTab, setContributionSelectedTab] =
    useState<number>(0);
  const [emissionContribution, setEmissionContribution] = useState<
    (
      | IEnergyEmissionContribution
      | IEquipmentEmissionContribution
      | IProcessEmissionContribution
      | IEnergyCostContribution
      | IEquipmentCostContribution
      | IProcessCostContribution
    )[]
  >(proPlanResults?.energyEmissionContribution);

  const isEmissionTable = selectedTab === 0;
  const contributionTabLabels = [
    `${t("proPlan.tabs.energySource")}`,
    `${t("proPlan.tabs.process")}`,
    `${t("proPlan.tabs.equipment")}`,
  ];

  const availableDates = {
    startDate:
      convertDateToUTC(new Date(proPlanResults?.emissionSummary[0].date)) ||
      new Date(),
    endDate:
      convertDateToUTC(
        new Date(
          proPlanResults?.emissionSummary[
            proPlanResults?.emissionSummary.length - 1
          ].date
        )
      ) || new Date(),
    key: "selection",
  };

  const getDatesForLabels = () => {
    const dates: string[] = [];
    for (let i = 0; i < emissionContribution.length; i++) {
      const date = emissionContribution[i].date;
      if (!dates.includes(date)) {
        dates.push(date);
      }
    }
    return dates;
  };

  const getLabelsForDatasets = () => {
    const datasetsLabels: ILabelForDatasets[] = [];
    for (let i = 0; i < emissionContribution.length; i++) {
      const label = emissionContribution[i].labelEs;
      if (!datasetsLabels.find(({ labelEs }) => label === labelEs)) {
        datasetsLabels.push({
          labelEs: label,
          labelEn: emissionContribution[i].labelEn,
        });
      }
    }
    return datasetsLabels;
  };

  const colorsForChart = [
    colors.green2,
    colors.green4,
    colors.blue1,
    colors.blue2,
    colors.blue3,
  ];

  const stackedData = {
    labels: getDatesForLabels(),
    datasets: getLabelsForDatasets()
      .map((label, index) => ({
        key: index,
        label: isSpanishLanguageSelected ? label.labelEs : label.labelEn,
        data: emissionContribution
          .filter(({ labelEs }) => labelEs === label.labelEs)
          .map(({ value }) => value),
        backgroundColor: colorsForChart[index],
        order: 2,
      }))
      .concat([
        {
          type: "line",
          label: "Total",
          data: getDatesForLabels().map((date) =>
            emissionContribution
              .filter((contribution) => date === contribution.date)
              .reduce((acc, currentValue) => acc + currentValue.value, 0)
          ),
          borderWidth: 1,
          borderColor: colors.blue3,
          tension: 0.4,
          pointStyle: "circle",
          pointBackgroundColor: colors.red1,
          pointBorderColor: colors.red1,
          pointRadius: 4,
          order: 1,
        } as any,
      ]),
  };

  const doughnutData = {
    labels: getLabelsForDatasets().map(({ labelEn, labelEs }) =>
      isSpanishLanguageSelected ? labelEs : labelEn
    ),
    datasets: [
      {
        data: getLabelsForDatasets().map(({ labelEs }) =>
          emissionContribution
            .filter((contribution) => labelEs === contribution.labelEs)
            .reduce((acc, currentValue) => acc + currentValue.value, 0)
        ),
        backgroundColor: colorsForChart,
        hoverOffset: 4,
      },
    ],
  };

  const barChartConfig = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom" as const,
        labels: {
          usePointStyle: true,
          pointStyle: "rectRounded",
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        stacked: true,
        title: {
          display: true,
          text: `${t("proPlan.axisLabels.date")}`,
        },
        grid: {
          lineWidth: 0,
        },
      },
      y: {
        stacked: true,
        display: true,
        title: {
          display: true,
          text: isEmissionTable ? "kg CO2-eq" : "USD",
        },

        grid: {
          lineWidth: 0,
        },
      },
    },
  };

  const doughnutConfig = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },

      datalabels: {
        formatter: (value: any, ctx: any) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map((data: any) => sum += data);
          let percentage = ((value * 100) / sum).toFixed(2) + "%";
          return percentage;
        },
        color: "#fff",
      },
    },
  };

  const [selectedDateRange, setSelectedDateRange] = useState<any | null>([
    availableDates,
  ]);

  useEffect(() => {
    setEmissionContribution(
      proPlanResults?.energyEmissionContribution?.filter(
        ({ date }) =>
          convertDateToUTC(new Date(date)) >= selectedDateRange[0].startDate &&
          convertDateToUTC(new Date(date)) <= selectedDateRange[0].endDate
      )
    );
  }, [selectedDateRange, proPlanResults]);

  useEffect(() => {
    switch (selectedTab) {
      case 0:
        switch (contributionSelectedTab) {
          case 0:
            return setEmissionContribution(
              proPlanResults?.energyEmissionContribution
            );
          case 1:
            return setEmissionContribution(
              proPlanResults?.processEmissionContribution
            );
          case 2:
            return setEmissionContribution(
              proPlanResults?.equipmentEmissionContribution
            );
          default:
            return setEmissionContribution(
              proPlanResults?.energyEmissionContribution
            );
        }
      case 1:
        switch (contributionSelectedTab) {
          case 0:
            return setEmissionContribution(
              proPlanResults?.energyCostContribution
            );
          case 1:
            return setEmissionContribution(
              proPlanResults?.processCostContribution
            );
          case 2:
            return setEmissionContribution(
              proPlanResults?.equipmentCostContribution
            );
          default:
            return setEmissionContribution(
              proPlanResults?.energyCostContribution
            );
        }
      default:
        return setEmissionContribution(
          proPlanResults?.energyEmissionContribution
        );
    }
  }, [selectedTab, contributionSelectedTab, proPlanResults.energyConsumption, proPlanResults.energyEmissionContribution, proPlanResults.energyCostContribution, proPlanResults.equipmentCostContribution, proPlanResults.processCostContribution, proPlanResults.equipmentEmissionContribution, proPlanResults.processEmissionContribution]);

  const getSelectionTabs = () => (
    <div className={styles.contributionTabsContainer}>
      {contributionTabLabels.map((label, index) => (
        <button
          key={index}
          onClick={() => setContributionSelectedTab(index)}
          className={classnames(styles.button, {
            [styles.selected]: contributionSelectedTab === index,
          })}
        >
          {label}
        </button>
      ))}
    </div>
  );

  const getContributions = () => (
    <div className={styles.mainContainer} id="refProCriticPoints">
      <SelectableTabs
        labels={[t("proPlan.tabs.emissions"), t("proPlan.tabs.costs")]}
        onSelect={setSelectedTab}
        selectedIndex={selectedTab}
      />
      {getSelectionTabs()}
      <div className={styles.innerContainer}>
        <div className={styles.graphContainer}>
          <Bar data={stackedData} options={barChartConfig} />
        </div>

        <div className={styles.secondSection}>
          <div className={styles.donutChart}>
            <Doughnut data={doughnutData} options={doughnutConfig} />
          </div>
          <div className={styles.datePicker}>
            <span>{t("proPlan.selectRange")}</span>
            <DateRangePicker
              onChange={(item) => setSelectedDateRange([item.selection])}
              moveRangeOnFirstSelection={false}
              editableDateInputs={true}
              showMonthAndYearPickers={false}
              staticRanges={[]}
              inputRanges={[]}
              ranges={selectedDateRange}
              rangeColors={[colors.green4]}
              shownDate={availableDates.startDate}
              minDate={availableDates.startDate}
              maxDate={availableDates.endDate}
            />
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <PungoDemoAccordion
      title={t("proPlan.labels.contribution")}
      content={getContributions()}
      isPro
    />
  );
};

export default ContributionSection;
