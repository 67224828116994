import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { changeLanguage } from "i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "app/hooks";
import ReactPlayer from "react-player/lazy";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import TeamSection from "./sections/TeamSection";
import BenefitsSection from "./sections/BenefitsSection";
import WorkflowSection from "./sections/WorkflowSection";
import FAQSection from "./sections/FAQSection";
import OpinionSection from "./sections/OpinionSection";
import PlanByTransactionSection from "./sections/PlanByTransactionSection";
import PlanTableSection from "./sections/PlanTableSection";
import ContactForm from "./sections/ContactForm";
import ContactUsSection from "./sections/ContactUsSection";
import PartnersSection from "./sections/PartnersSection";
import AnimationSection from "./sections/AnimationSection";
import PungoButton from "../../pungo-ui/PungoButton";
import { selectSurveyStatus, selectUserName } from "store/slices/auth";
import { getSurvey } from "store/actions/surveyActions";
import styles from "./index.module.scss";
import breakpoints from "../../styles/export.module.scss";

const AucaPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLogged = useSelector(selectUserName);
  const isSurveyCompleted = useSelector(selectSurveyStatus);
  const isSpanishLanguageSelected = i18n.language === "es";

  const mobileViewForVideo = useMediaQuery(
    json2mq({
      minWidth: breakpoints.md,
    })
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isSurveyCompleted) {
      dispatch(getSurvey());
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoToDemo = () => {
    if (!isLogged) {
      navigate("/signup");
    } else {
      if (isSurveyCompleted) {
        navigate("/calculation");
      } else {
        navigate("/survey");
      }
    }
  };

  return (
    <div className={styles.mainContainer}>
      <AnimationSection />
      <div className={styles.button}>
        <PungoButton
          onClick={handleGoToDemo}
          label={isLogged ? `${t("goToFreePlan")}` : `${t("demoLabel")}`}
          color="secondary"
          classNames={styles.demoButton}
        />
      </div>
      <div className={styles.video}>
        <ReactPlayer
          url="https://d2ay24nheu7ro1.cloudfront.net/auca-corporate-video_es.mp4"
          width={!mobileViewForVideo ? 350 : 640}
          height={!mobileViewForVideo ? 197 : 360}
          controls
        />
      </div>
      <BenefitsSection />
      <WorkflowSection />
      <PlanByTransactionSection />
      <PlanTableSection />
      <OpinionSection />
      <FAQSection />
      <TeamSection />
      <PartnersSection />
      <ContactForm />
      <ContactUsSection />
      <div className={styles.languageSection}>
        <PungoButton
          label={`${t("language.englishLabel")}`}
          type="text"
          onClick={() => changeLanguage("en")}
          color="secondary"
          disabled={!isSpanishLanguageSelected}
        />
        <PungoButton
          label={`${t("language.spanishLabel")}`}
          type="text"
          onClick={() => changeLanguage("es")}
          color="secondary"
          disabled={isSpanishLanguageSelected}
        />
      </div>
      <PungoButton
        label={`${t("politicsAndReferenceTerms")}`}
        type="text"
        onClick={() => navigate("/terms")}
        color="secondary"
        classNames={styles.politics}
      />
      <div className={styles.copyright}>Copyright © 2023 Pungo</div>
    </div>
  );
};

export default AucaPage;
