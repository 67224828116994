import React, {useEffect} from "react";
import FooterOnePageSection from "components/AucaComponents/FooterOnePageSection";
import ContactUsSection from "components/AucaPage/sections/ContactUsSection";
import ContactForm from "components/AucaPage/sections/ContactForm";
import styles from "./index.module.scss";

const ContactUsPage: React.FC = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.ContactUsPage}>
      <div className={styles.mainBlock}>
        <ContactForm />
        <ContactUsSection />
        <FooterOnePageSection />
      </div>
    </div>
  );
};

export default ContactUsPage;
