import React from "react";
import { useTranslation } from "react-i18next";
import PungoSectionCard from "pungo-ui/PungoSectionCard";
import useMediaQuery from "@mui/material/useMediaQuery";
import breakpoints from "styles/export.module.scss";
import json2mq from "json2mq";

import colors from "styles/export.module.scss";
import styles from "./index.module.scss";
import PungoOpinionCard from "pungo-ui/PungoOpinionCard";
import PungoCustomizedCarousel from "pungo-ui/PungoCustomizedCarousel";

const OpinionSection: React.FC = () => {
  const { t } = useTranslation();

  const desktopView = useMediaQuery(
    json2mq({
      minWidth: breakpoints.lg,
    })
  );

  const opinionData = [
    {
      id: "opinion1",
      name: t("opinionSection.lorena.author"),
      opinion: t("opinionSection.lorena.body"),
      color: colors.green2,
    },
    {
      id: "opinion2",
      name: t("opinionSection.daniel.author"),
      opinion: t("opinionSection.daniel.body"),
      color: colors.blue2,
    },
    {
      id: "opinion3",
      name: t("opinionSection.roman.author"),
      opinion: t("opinionSection.roman.body"),
      color: colors.green4,
    },
  ];

  const getOpinionSection = () =>
    opinionData.map(({ id, name, opinion, color }) => (
      <div key={id} className={styles.cardContainer}>
        <PungoOpinionCard
          color={color}
          author={name}
          opinion={`"${opinion}".`}
          key={name}
        />
      </div>
    ));

  return (
    <PungoSectionCard title={t("sectionLabel.opinion")}>
      <div className={styles.container}>
        {desktopView ? (
          getOpinionSection()
        ) : (
          <div className={styles.carouselContainer}>
            <PungoCustomizedCarousel
              slides={getOpinionSection()}
              height={400}
            />
          </div>
        )}
      </div>
    </PungoSectionCard>
  );
};

export default OpinionSection;
