import React from "react";
import { useTranslation } from "react-i18next";

import planet from "../../../assets/AucaImages/planet.svg";

import styles from "./index.module.scss";

const AnimationSection: React.FC = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;

  return (
    <section className={styles.container} id="start">
      <div className={styles.text}>
        {language === "es" ? (
          <p className={styles.header}>
            <b>Bienvenido a AUCA:</b>&nbsp; La herramienta digital para el
             <span className={styles.accents}> cálculo</span>,
            &nbsp; <span className={styles.accents}>monitoreo </span>y
            &nbsp; <span className={styles.accents}>reducción</span> del consumo de energia y las emisiones de carbono en la industria.
          </p>
        ) : (
          <p className={styles.header}>
            <b>Welcome to AUCA: </b>&nbsp; The digital tool for the 
            &nbsp; <span className={styles.accents}>calculation </span>,
            &nbsp; <span className={styles.accents}>monitoring </span> and
            &nbsp; <span className={styles.accents}>reduction </span>  of energy consumption and carbon emissions in the industry.
          </p>
        )}
      </div>
      <img className={styles.gif} src={planet} alt="Monitor animation" />
    </section>
  );
};

export default AnimationSection;
