import React, { useState, useEffect, Fragment } from "react";

import { useTranslation } from "react-i18next";
import { subscribePro, subscribeSimple } from "store/actions/planActions";
import { useAppDispatch } from "app/hooks";
import { useSelector } from "react-redux";
import { selectUserName } from "store/slices/auth";
import { useNavigate } from "react-router-dom";
import PungoPlanButton from "pungo-ui/PungoPlanButton";
import PungoSectionCard from "pungo-ui/PungoSectionCard";
import { CheckRounded } from "@mui/icons-material";
import { RemoveRounded } from "@material-ui/icons";

import styles from "./index.module.scss";
import ChevronLeft from "assets/PungoIcons/ChevronLeft.svg";
import ChevronRight from "assets/PungoIcons/ChevronRight.svg";

const PlanTableSection: React.FC = () => {
  enum PlanType {
    SIMPLE = "simple",
    PRO = "pro",
    PREMIUM = "premium",
  }

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLogged = useSelector(selectUserName);
  const { t } = useTranslation();

  // The data constant defines the checkmarks that appear on each plan for each option.
  // with format T the text appears as a title, bold text and without checkmarks
  // if planN == Y then a checkmark is set
  const data = [
    { format: "T", txt: `${t("plansSection.features.0")}`, plan1: "50", plan2: "500", plan3: "Ilimitado" },
    { format: "T", txt: `${t("plansSection.features.1")}`, plan1: "", plan2: "", plan3: "" },
    { format: "D", txt: `${t("plansSection.features.2")}`, plan1: "N", plan2: "Y", plan3: "Y" },
    { format: "D", txt: `${t("plansSection.features.3")}`, plan1: "N", plan2: "Y", plan3: "Y" },
    { format: "T", txt: `${t("plansSection.features.4")}`, plan1: "", plan2: "", plan3: "" },
    { format: "D", txt: `${t("plansSection.features.5")}`, plan1: "Y", plan2: "Y", plan3: "Y" },
    { format: "D", txt: `${t("plansSection.features.6")}`, plan1: "N", plan2: "N", plan3: "Y" },
    { format: "D", txt: `${t("plansSection.features.7")}`, plan1: "N", plan2: "N", plan3: "Y" },
    { format: "T", txt: `${t("plansSection.features.8")}`, plan1: "", plan2: "", plan3: "" },
    { format: "D", txt: `${t("plansSection.features.9")}`, plan1: "Y", plan2: "Y", plan3: "Y" },
    { format: "D", txt: `${t("plansSection.features.10")}`, plan1: "Y", plan2: "Y", plan3: "Y" },
    { format: "T", txt: `${t("plansSection.features.11")}`, plan1: "", plan2: "", plan3: "" },
    { format: "D", txt: `${t("plansSection.features.12")}`, plan1: "Y", plan2: "Y", plan3: "Y" },
    { format: "D", txt: `${t("plansSection.features.13")}`, plan1: "Y", plan2: "Y", plan3: "Y" },
    { format: "T", txt: `${t("plansSection.features.14")}`, plan1: "", plan2: "", plan3: "" },
    { format: "D", txt: `${t("plansSection.features.15")}`, plan1: "Y", plan2: "Y", plan3: "Y" },
    { format: "D", txt: `${t("plansSection.features.16")}`, plan1: "N", plan2: "Y", plan3: "Y" },
  ];

  const [style, setStyle] = useState("table1");
  const [count, setCount] = useState(1);
  const [varTableTop, setVarTableTop] = useState(0);
  const [varTableBottom, setVarTableBottom] = useState(0);
  const [varTableX, setVarTableX] = useState(0);
  const [varTableWidth, setVarTableWidth] = useState(0);
  const [varScreenHeight, setVarScreenHeight] = useState(window.innerHeight);
  const [varScreenWidth, setVarScreenWidth] = useState(window.innerWidth);

  const selectColumn = (name: string) => {
    name === "1" ? setStyle("table1") : name === "2" ? setStyle("table2") : setStyle("table3");
  };

  const nextColumn = () => {
    count < 3 ? setCount(count + 1) : setCount(1);
    count === 1 ? setStyle("table1") : count === 2 ? setStyle("table2") : setStyle("table3");
  };

  const previousColumn = () => {
    count > 1 ? setCount(count - 1) : setCount(3);
    count === 1 ? setStyle("table1") : count === 2 ? setStyle("table2") : setStyle("table3");
  };

  const handleGoToPlan = (plan: string) => {
    switch (plan) {
      case "simple":
        if (!isLogged) {
          navigate("/signup");
        } else {
          return dispatch(subscribeSimple());
        }
        return;
      case "pro":
        if (!isLogged) {
          navigate("/signup");
        } else {
          return dispatch(subscribePro());
        }
        return;
      case "premium":
        return navigate("/contactus");
      default:
        break;
    }
  };

  const tablePosition = () => {
    if (document.getElementById("planTable")) {
      const rect = document.getElementById("planTable")?.getBoundingClientRect();
      setVarTableTop(rect!.top);
      setVarTableBottom(rect!.bottom);
      setVarTableX(rect!.x);
      setVarTableWidth(rect!.width);

      if (varTableTop > 0 && varTableBottom > varScreenHeight) {
        document.getElementById("leftChevron")!.style.top = (varTableTop + varScreenHeight) / 2 + "px";
        document.getElementById("rightChevron")!.style.top = (varTableTop + varScreenHeight) / 2 + "px";
      } else if (varTableBottom > varScreenHeight) {
        document.getElementById("leftChevron")!.style.top = varScreenHeight / 2 + "px";
        document.getElementById("rightChevron")!.style.top = varScreenHeight / 2 + "px";
      } else {
        document.getElementById("leftChevron")!.style.top = varTableBottom / 2 + "px";
        document.getElementById("rightChevron")!.style.top = varTableBottom / 2 + "px";
      }

      if (varTableX + varTableWidth + 100 < varScreenWidth) {
        document.getElementById("leftChevron")!.style.left = varTableX - 50 + "px";
        document.getElementById("rightChevron")!.style.left = varTableX + varTableWidth + 10 + "px";
      } else {
        document.getElementById("leftChevron")!.style.left = 10 + "px";
        document.getElementById("rightChevron")!.style.left = varScreenWidth - 50 + "px";
      }
    }
  };

  const screenDimension = () => {
    setVarScreenHeight(window.innerHeight);
    setVarScreenWidth(window.innerWidth);
    tablePosition();
  };

  useEffect(() => {
    window.addEventListener("scroll", tablePosition);
    window.addEventListener("resize", screenDimension);

    return () => window.removeEventListener("scroll", tablePosition);
  });

  return (
    <PungoSectionCard title={`${t("sectionLabel.plansDescription")}`} description={`${t("sectionContent.plans")}`} id="plans-2">
      <div
        className={styles.chevronLeft}
        onClick={() => {
          previousColumn();
        }}
        id="leftChevron"
      >
        <img src={ChevronLeft} alt="Left Chevron" />
      </div>

      <div className={styles.containerPlansTable}>
        <table className={`${styles[style]}`} id="planTable">
          <tbody>
            <tr>
              <td> &nbsp; </td>
              <td> &nbsp; </td>
              <td> &nbsp; </td>
              <td> &nbsp; </td>
              <td className={styles.rowPopular02}> {t("plansSection.mostPopularLabel")} </td>
              <td> &nbsp; </td>
              <td> &nbsp; </td>
            </tr>

            <tr>
              <td> &nbsp; </td>
              <td> &nbsp; </td>
              <td className={styles.rowTitle01}> {t("planByTransaction.Plan2.name")} </td>
              <td> &nbsp; </td>
              <td className={styles.rowTitle02}> {t("planByTransaction.Plan3.name")} </td>
              <td> &nbsp; </td>
              <td className={styles.rowTitle03}> {t("planByTransaction.Plan4.name")} </td>
            </tr>

            {data.map((val, key) => {
              return (
                <Fragment key={key}>
                  <tr className={val.format === "T" ? styles.separatorTitle : styles.separator}>
                    <td></td>
                    <td></td>
                    <td className={styles.col01}></td>
                    <td></td>
                    <td className={styles.col02}></td>
                    <td></td>
                    <td className={styles.col03}></td>
                  </tr>

                  <tr>
                    <td className={val.format === "T" ? styles.descriptionTitle : styles.description}>{val.txt}</td>
                    <td className={styles.whiteSpace}>&nbsp;</td>
                    <td className={styles.col01}>
                      {" "}
                      {val.format === "T" ? (
                        val.plan1
                      ) : val.plan1 === "Y" ? (
                        <CheckRounded fontSize="inherit" className={styles.check} />
                      ) : (
                        <RemoveRounded fontSize="inherit" className={styles.check} />
                      )}
                    </td>
                    <td className={styles.whiteSpace}>&nbsp;</td>
                    <td className={styles.col02}>
                      {" "}
                      {val.format === "T" ? (
                        val.plan2
                      ) : val.plan2 === "Y" ? (
                        <CheckRounded fontSize="inherit" className={styles.check} />
                      ) : (
                        <RemoveRounded fontSize="inherit" className={styles.check} />
                      )}
                    </td>
                    <td className={styles.whiteSpace}>&nbsp;</td>
                    <td className={styles.col03}>
                      {" "}
                      {val.format === "T" ? (
                        val.plan3
                      ) : val.plan3 === "Y" ? (
                        <CheckRounded fontSize="inherit" className={styles.check} />
                      ) : (
                        <RemoveRounded fontSize="inherit" className={styles.check} />
                      )}
                    </td>
                  </tr>
                </Fragment>
              );
            })}

            <tr className={styles.separator}>
              <td></td>
              <td></td>
              <td className={styles.col01}></td>
              <td></td>
              <td className={styles.col02}></td>
              <td></td>
              <td className={styles.col03}></td>
            </tr>

            <tr>
              <td className={styles.descriptionBottom}>&nbsp;</td>
              <td className={styles.whiteSpaceBottom}>&nbsp;</td>
              <td className={styles.lastRow03}>
                <PungoPlanButton label={`${t("plansSection.subscribeLabel")}`} onClick={() => handleGoToPlan(PlanType.SIMPLE)} color={"secondary"} classNames={styles.buttonSuscribe} />
              </td>
              <td className={styles.whiteSpaceBottom}>&nbsp;</td>
              <td className={styles.lastRow05}>
                <PungoPlanButton label={`${t("plansSection.subscribeLabel")}`} onClick={() => handleGoToPlan(PlanType.PRO)} color={"tertiary"} classNames={styles.buttonSuscribe} />
              </td>
              <td className={styles.whiteSpaceBottom}>&nbsp;</td>
              <td className={styles.lastRow07}>
                <PungoPlanButton label={`${t("plansSection.contactUsLabel")}`} onClick={() => handleGoToPlan(PlanType.PREMIUM)} color={"primary"} classNames={styles.buttonSuscribe} />
              </td>
            </tr>
          </tbody>
        </table>

        <div id={styles["planButtonSelector"]}>
          <button
            className={styles.button1}
            onClick={() => {
              selectColumn("1");
            }}
          ></button>

          <button
            className={styles.button2}
            onClick={() => {
              selectColumn("2");
            }}
          ></button>

          <button
            className={styles.button3}
            onClick={() => {
              selectColumn("3");
            }}
          ></button>
        </div>
      </div>

      <div
        className={styles.next}
        onClick={() => {
          nextColumn();
        }}
        id="nextEl"
      ></div>

      <div
        className={styles.chevronRight}
        onClick={() => {
          nextColumn();
        }}
        id="rightChevron"
      >
        <img src={ChevronRight} alt="Right Chevron" />
      </div>
    </PungoSectionCard>
  );
};

export default PlanTableSection;
