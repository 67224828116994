import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useAppDispatch } from "app/hooks";
import { getCalculateEnergySources, getMockedDataForProPlan, downloadCalculationTemplate, uploadCalculationTemplate } from "store/actions/planActions";
import { displaySnackbarAPIError } from "store/actions/utils";
import { selectSurveyStatus, selectUserRole, selectUserName } from "store/slices/auth";
import { selectFetchedProResultsStatus } from "store/slices/plan";
import DataInputProSection from "components/AucaComponents/DataInputProSection";
import FooterOnePageSection from "components/AucaComponents/FooterOnePageSection";
import ResultsProSection from "components/AucaComponents/ResultsProSection";
import PungoAccordion from "pungo-ui/PungoAccordion";
import PungoButton from "pungo-ui/PungoButton";
import PungoSelectLanguage from "pungo-ui/PungoSelectLanguage";
import { Download, UploadFile } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import json2mq from "json2mq";
import breakpoints from "styles/export.module.scss";
import styles from "./index.module.scss";

const CalculationBatch: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isSurveyCompleted = useSelector(selectSurveyStatus);
  const userRoles = useSelector(selectUserRole);
  const isLogged = useSelector(selectUserName);
  const userRol = useSelector(selectUserRole)[0];
  const isFreeUser = userRoles?.includes("free_plan");
  const areProResultsFetched = useSelector(selectFetchedProResultsStatus);
  const [renderPage, setRenderPage] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const mobileViewForVideo = useMediaQuery(
    json2mq({
      minWidth: breakpoints.md,
    })
  );

  const clearFileInput = (ctrl: any) => {
    try {
      ctrl.value = null;
    } catch (ex) {}
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  };

  const onUploadFile = () => {
    if (uploadedFile) {
      const formData = new FormData();
      formData.append("file", uploadedFile);
      formData.append("filename", uploadedFile.name);

      dispatch(uploadCalculationTemplate(formData));
      setUploadedFile(null);
      clearFileInput(document.getElementById("file"));
    }
  };

  const handleDownloadTemplate = () => {
    if (isFreeUser) {
      dispatch(displaySnackbarAPIError("CalculatorBatch.loadData.noForFreeUser"));
    } else {
      dispatch(downloadCalculationTemplate());
    }
  };

  const handleUploadClick = () => {
    if (isFreeUser) {
      dispatch(displaySnackbarAPIError("CalculatorBatch.loadData.noForFreeUser"));
    } else if (!uploadedFile) {
      dispatch(displaySnackbarAPIError("CalculatorBatch.loadData.pending"));
    } else {
      onUploadFile();
    }
  };

  const getHowTo = () => (
    <div className={styles.planInstructions}>
      <div className={styles.text}>
        <Trans>{t("DemoPage.InstructionsSection.proSteps")}</Trans>
      </div>

      {/* Video pro plan tutorial */}

      <div className={styles.video}>
        <ReactPlayer
          url="https://d2ay24nheu7ro1.cloudfront.net/video_howto_plan_pro_es_v2.mp4"
          width={!mobileViewForVideo ? 350 : 640}
          height={!mobileViewForVideo ? 197 : 360}
          light="https://d2ay24nheu7ro1.cloudfront.net/image_video_howto_plan_pro_es_v2.png"
          controls
        />
      </div>
    </div>
  );

  const getDemoFreeUserMsg = () => (
    <div className={styles.infoMessage}>
      <Trans>{t("DemoPage.disclaimer")}</Trans>
    </div>
  );

  const downloadTemplateElement = () => {
    return <PungoButton label={`${t("manageProfile.downloadFileButton")}`} type="text" onClick={handleDownloadTemplate} color="primary" icon={<Download />} classNames={styles.selectButton} />;
  };

  const selectFileElement = () => {
    return (
      <input
        type="file"
        id="file"
        onChange={(event: any) => setUploadedFile(event.target.files[0])}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        className={styles.fileInput}
        disabled={isFreeUser}
      />
    );
  };

  const uploadFileElement = () => {
    return (
      <button onClick={handleUploadClick} color="secondary" className={styles.uploadButton}>
        <div className={styles.buttonMessage}>
          <div className={styles.buttonIcon}>
            <UploadFile />
          </div>
          <div className={styles.buttonText}>{`${t("manageProfile.uploadFileButton")}`} </div>
        </div>
      </button>
    );
  };

  const getFileManager = () => {
    return (
      <div className={styles.loadData}>
        <div className={styles.fileManager}>
          <div className={styles.elementFlex}>
            <div className={styles.element}>{downloadTemplateElement()}</div>
            <div className={styles.element}>{selectFileElement()}</div>
            <div className={styles.element}>{uploadFileElement()}</div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const plansAccessGrantArray = ["admin", "free_plan", "pro_plan"];

    if (!isLogged) {
      navigate("/login");
    } else if (plansAccessGrantArray.indexOf(userRol) < 0) {
      navigate("/login");
    } else if (!isSurveyCompleted && !(userRol === "company_user") && !(userRol === "company_admin") && !(userRol === "admin")) {
      navigate("/survey");
    } else {
      setRenderPage(true);
      window.scrollTo(0, 0);
      dispatch(getCalculateEnergySources());
      if (isFreeUser) {
        dispatch(getMockedDataForProPlan());
      }
    }
  }, [navigate, userRol, isLogged, isSurveyCompleted, dispatch, isFreeUser]);

  return (
    <div className={styles.mainPage}>
      {renderPage && (
        <section className={styles.container}>
          <div className={styles.title}>
            {t("CalculatorBatch.title")} {isFreeUser && "Demo"}
          </div>
          <div className={styles.accordion}>
            <PungoAccordion title={t("PlanSelect.howTo")} content={getHowTo()} noborder={true} />
          </div>
          {getFileManager()}
          {isFreeUser && getDemoFreeUserMsg()}
          {areProResultsFetched ? <DataInputProSection /> : undefined}
          {areProResultsFetched ? <ResultsProSection /> : undefined}
          <FooterOnePageSection />
          <PungoSelectLanguage />
        </section>
      )}
    </div>
  );
};

export default CalculationBatch;
