import React from "react";
import { useTranslation } from "react-i18next";
import PungoSectionCard from "pungo-ui/PungoSectionCard";
import PungoBenefitsCard from "pungo-ui/PungoBenefitsCard";
import BenefitCompetitiveness from "assets/PungoIcons/BenefitCompetitiveness.svg";
import BenefitCosts from "assets/PungoIcons/BenefitCosts.svg";
import BenefitSustainability from "assets/PungoIcons/BenefitSustainability.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import breakpoints from "styles/export.module.scss";
import json2mq from "json2mq";

import colors from "styles/export.module.scss";
import styles from "./index.module.scss";
import PungoCustomizedCarousel from "pungo-ui/PungoCustomizedCarousel";

const BenefitsSection: React.FC = () => {
  const { t } = useTranslation();

  const desktopView = useMediaQuery(
    json2mq({
      minWidth: breakpoints.lg,
    })
  );

  const benefitsData = [
    {
      id: "benefits1",
      header: t("benefictsSection.sustainability.header"),
      description: t("benefictsSection.sustainability.body"),
      color: colors.green2,
      image: BenefitSustainability,
      imagePosition: "left" as const,
    },
    {
      id: "benefits2",
      header: t("benefictsSection.costs.header"),
      description: t("benefictsSection.costs.body"),
      color: colors.green4,
      image: BenefitCosts,
      imagePosition: "right" as const,
    },
    {
      id: "benefits3",
      header: t("benefictsSection.competitiveness.header"),
      description: t("benefictsSection.competitiveness.body"),
      color: colors.blue2,
      image: BenefitCompetitiveness,
      imagePosition: "left" as const,
    },
  ];

  const getBenefitSection = () =>
    benefitsData.map(
      ({ id, header, description, color, image, imagePosition }) => (
        <div key={id} className={styles.cardContainer}>
          <PungoBenefitsCard
            key={header}
            description={description}
            color={color}
            title={header}
            image={image}
            imagePosition={imagePosition}
          />
        </div>
      )
    );

  return (
    <PungoSectionCard
      title={t("sectionLabel.benefits")}
      description={`${t("sectionContent.benefits")}`}
      id="benefits"
    >
      <div className={styles.container}>
        {desktopView ? (
          getBenefitSection()
        ) : (
          <div className={styles.carouselContainer}>
            <PungoCustomizedCarousel
              slides={getBenefitSection()}
              height={321}
            />
          </div>
        )}
      </div>
    </PungoSectionCard>
  );
};

export default BenefitsSection;
