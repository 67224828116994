import React from "react";
import { Trans, useTranslation } from "react-i18next";
import PungoSectionCard from "pungo-ui/PungoSectionCard";
import CarbonFootprintSection from "components/AucaComponents/CarbonFootprintSection";
import styles from "./index.module.scss";
import PungoAccordion from "pungo-ui/PungoAccordion";

const FAQSection: React.FC = () => {
  const { t } = useTranslation();

  const questionFAQ01 = () => (
    <div className={styles.text}>
      <Trans>{t("FAQSection.FAQ01.body")}</Trans>
    </div>
  );

  const questionFAQ02 = () => (
    <div className={styles.text}>
      <Trans>{t("FAQSection.FAQ02.body")}</Trans>
    </div>
  );

  const questionFAQ03 = () => (
    <div className={styles.text}>
      <Trans>{t("FAQSection.FAQ03.body")}</Trans>
    </div>
  );

  const questionFAQ04 = () => (
    <div className={styles.text}>
      <Trans>{t("FAQSection.FAQ04.body")}</Trans>
    </div>
  );

  const questionFAQ05 = () => (
    <div className={styles.text}>
      <Trans>{t("FAQSection.FAQ05.content1")}</Trans>

      <ul>
        <li>
          <Trans>{t("FAQSection.FAQ05.item1")}</Trans>
        </li>
        <li>
          <Trans>{t("FAQSection.FAQ05.item2")}</Trans>
        </li>
      </ul>

      <Trans>{t("FAQSection.FAQ05.content2")}</Trans>
    </div>
  );

  const questionFAQ06 = () => (
    <>
      <div className={styles.text}>
        <Trans>{t("FAQSection.FAQ06.scope1")}</Trans>

        <ul>
          <li>
            <Trans>{t("FAQSection.FAQ06.item11")}</Trans>
          </li>
          <li>
            <Trans>{t("FAQSection.FAQ06.item12")}</Trans>
          </li>
          <li>
            <Trans>{t("FAQSection.FAQ06.item13")}</Trans>
          </li>
        </ul>

        <Trans>{t("FAQSection.FAQ06.scope2")}</Trans>

        <ul>
          <li>
            <span className={styles.accents}>
              <Trans>{t("FAQSection.FAQ06.item21")}</Trans>
            </span>
            <Trans>{t("FAQSection.FAQ06.item21Description")}</Trans>
          </li>
          <li>
            <span className={styles.accents}>
              <Trans>{t("FAQSection.FAQ06.item22")}</Trans>
            </span>
            <Trans>{t("FAQSection.FAQ06.item22Description")}</Trans>
          </li>
          <li>
            <span className={styles.accents}>
              <Trans>{t("FAQSection.FAQ06.item23")}</Trans>
            </span>
            <Trans>{t("FAQSection.FAQ06.item23Description")}</Trans>
          </li>
        </ul>
      </div>

      <CarbonFootprintSection />
    </>
  );

  const questionFAQ07 = () => (
    <div className={styles.text}>
      <Trans>{t("FAQSection.FAQ07.p1")}</Trans>

      <ul>
        <li>
          <Trans>{t("FAQSection.FAQ07.li1")}</Trans>
        </li>
        <li>
          <Trans>{t("FAQSection.FAQ07.li2")}</Trans>
        </li>
        <li>
          <Trans>{t("FAQSection.FAQ07.li3")}</Trans>
        </li>
        <li>
          <Trans>{t("FAQSection.FAQ07.li4")}</Trans>
        </li>
      </ul>
    </div>
  );

  const questionFAQ08 = () => (
    <>
      <div className={styles.text}>
        <Trans>{t("FAQSection.FAQ08.p01")}</Trans>
      </div>
      <div className={styles.text}>
        <Trans>{t("FAQSection.FAQ08.p02")}</Trans>
      </div>
    </>
  );

  const questionFAQ09 = () => (
    <>
      <div className={styles.text}>
        <Trans>{t("FAQSection.FAQ09.p01")}</Trans>
        <Trans>{t("FAQSection.FAQ09.p02")}</Trans>
        <ul>
          <li>
            <Trans>{t("FAQSection.FAQ09.li1")}</Trans>
          </li>
          <li>
            <Trans>{t("FAQSection.FAQ09.li2")}</Trans>
          </li>
          <li>
            <Trans>{t("FAQSection.FAQ09.li3")}</Trans>
          </li>
        </ul>
      </div>
    </>
  );

  const questionFAQ10 = () => (
    <div className={styles.text}>
      <Trans>{t("FAQSection.FAQ10.body")}</Trans>
    </div>
  );

  const questionFAQ11 = () => (
    <div className={styles.text}>
      <Trans>{t("FAQSection.FAQ11.body")}</Trans>
    </div>
  );

  return (
    <div className={styles.containerFAQSection}>
      <PungoSectionCard title={t("FAQSection.mainTitle")} id="FAQs">
        <div className={styles.FAQSection}>
          <div className={styles.accordionContainer}>
            <PungoAccordion title={t("FAQSection.FAQ05.title")} content={questionFAQ05()} />
          </div>
          <div className={styles.accordionContainer}>
            <PungoAccordion title={t("FAQSection.FAQ06.title")} content={questionFAQ06()} />
          </div>
          <div className={styles.accordionContainer}>
            <PungoAccordion title={t("FAQSection.FAQ07.title")} content={questionFAQ07()} />
          </div>
          <div className={styles.accordionContainer}>
            <PungoAccordion title={t("FAQSection.FAQ08.title")} content={questionFAQ08()} />
          </div>
          <div className={styles.accordionContainer}>
            <PungoAccordion title={t("FAQSection.FAQ09.title")} content={questionFAQ09()} />
          </div>

          <div className={styles.subtitle}>{t("FAQSection.subTitle")}</div>

          <div className={styles.accordionContainer}>
            <PungoAccordion title={t("FAQSection.FAQ03.title")} content={questionFAQ03()} />
          </div>
          <div className={styles.accordionContainer}>
            <PungoAccordion title={t("FAQSection.FAQ01.title")} content={questionFAQ01()} />
          </div>
          <div className={styles.accordionContainer}>
            <PungoAccordion title={t("FAQSection.FAQ02.title")} content={questionFAQ02()} />
          </div>
          <div className={styles.accordionContainer}>
            <PungoAccordion title={t("FAQSection.FAQ04.title")} content={questionFAQ04()} />
          </div>
          <div className={styles.accordionContainer}>
            <PungoAccordion title={t("FAQSection.FAQ10.title")} content={questionFAQ10()} />
          </div>
          <div className={styles.accordionContainer}>
            <PungoAccordion title={t("FAQSection.FAQ11.title")} content={questionFAQ11()} />
          </div>
        </div>
      </PungoSectionCard>
    </div>
  );
};

export default FAQSection;
