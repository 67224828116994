import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import { useAppDispatch } from "app/hooks";
import json2mq from "json2mq";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PungoMenu from "../../pungo-ui/PungoMenu";
import AucaLogo from "../../assets/AucaLogo.svg";
import PSShortLogo from "../../assets/PungoIcons/ShortIcon.svg";
import PungoClickable from "../../pungo-ui/PungoClickable";
import PungoLoader from "../../pungo-ui/PungoLoader";
import PungoLoginCircle from "pungo-ui/PungoLoginCircle";
import { selectOngoingAPICalls } from "store/slices/api";
import { saveVerticalMenu } from "store/slices/verticalMenu";
import classnames from "classnames";
import breakpoints from "../../styles/export.module.scss";
import styles from "./index.module.scss";

interface NavbarProps {
  type?: "home" | "user" | "plan" | "structure" | "planSelection";
}
interface INavbarButton {
  section: string;
  label: string;
  position?:number;
}

const Navbar: React.FC<NavbarProps> = (props) => {
  const { type = "user" } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const apiCalls = useSelector(selectOngoingAPICalls);
  const desktopView = useMediaQuery(
    json2mq({
      minWidth: breakpoints.xg,
    })
  );
  const mobileView = useMediaQuery(
    json2mq({
      minWidth: breakpoints.xlg,
    })
  );

  const homeButtons = [
    {
      section: "benefits",
      label: `${t("sectionLabel.benefits")}`,
    },
    {
      section: "workflow",
      label: `${t("sectionLabel.workflow")}`,
    },
    {
      section: "plans",
      label: `${t("sectionLabel.plans")}`,
    },
    {
      section: "FAQs",
      label: `${t("sectionLabel.FAQs")}`,
    },
    {
      section: "team",
      label: `${t("sectionLabel.team")}`,
    },
    {
      section: "partners",
      label: `${t("sectionLabel.partners")}`,
    },
    {
      section: "contactUs",
      label: `${t("sectionLabel.contactUs")}`,
    },
  ];

  const planButtons = [
    {
      section: "information",
      label: `${t("sectionLabel.information")}`,
    },
    {
      section: "plans",
      label: `${t("sectionLabel.plans")}`,
    },
    {
      section: "FAQs",
      label: `${t("sectionLabel.FAQs")}`,
    },
    {
      section: "contactUs",
      label: `${t("sectionLabel.contactUs")}`,
    },
  ];

  const structureButtons = [
    {
      section: "structureTree",
      label: `${t("sectionLabel.structure")}`,
    },
    {
      section: "team",
      label: `${t("sectionLabel.team")}`,
    },
    {
      section: "contactUs",
      label: `${t("sectionLabel.contactUs")}`,
    }
  ];

  const planSelectionButtons = [
    {
      section: "/calculation",
      label: `${t("selectCalculation")}`,
      position: 0,
    },
    {
      section: "/faqs",
      label: `${t("sectionLabel.FAQs")}`,
      position: 2,
    },
    {
      section: "/info",
      label: `${t("sectionLabel.plans")}`,
      position: 3,
    },
    {
      section: "/contactus",
      label: `${t("sectionLabel.contactUs")}`,
      position: 4,
    },
  ];

  const redirectToMainPage = () => navigate("/");

  const getNavbarButtons = (options: INavbarButton[]) =>
    options.map(({ section, label }) => (
      <Link
        activeClass={styles.activeButton}
        smooth
        spy
        to={section}
        offset={-120}
        className={styles.button}
        key={label}
      >
        {label}
      </Link>
    ));

    const getNavbarPageLink = (options: INavbarButton[]) =>
      options.map(({ section, label, position=0 }) => (
        <div
          key={position}
          className={styles.linkToPage}
          onClick={() => {
            dispatch(saveVerticalMenu(position));
            window.open(section, "_self");

          }}
        >
          {label}
        </div>
      ));


  const getOptions = (type: string) => {
    switch (type) {
      case "home":
        return getNavbarButtons(homeButtons);
      case "plan":
        return getNavbarButtons(planButtons);
      case "user":
        return undefined;
      case "structure":
        return getNavbarButtons(structureButtons);
      case "planSelection":
          return getNavbarPageLink(planSelectionButtons);
      default:
        return getNavbarButtons(homeButtons);
    }
  };

  return (
    <div className={classnames(styles.wrapper, { [styles.single]: !type })}>
      <div className={styles.navbarContainer}>
        {desktopView ? (
          <PungoClickable onClick={redirectToMainPage}>
            <img src={AucaLogo} alt="Pungo logo" className={styles.image} />
          </PungoClickable>
        ) : (
          <PungoClickable onClick={redirectToMainPage}>
            <img
              src={PSShortLogo}
              alt="Pungo logo"
              className={styles.shortImage}
            />
          </PungoClickable>
        )}
        <div className={styles.space} />
        <div
          className={classnames(styles.actionsContainer, {
            [styles.planPage]: type === "plan",
            [styles.user]: type === "user",
          })}
        >
          {!mobileView ? (
            <PungoMenu options={getOptions(type)} />
          ) : (
            getOptions(type)
          )}
          
          <PungoLoginCircle />
        </div>
      </div>
      {apiCalls.length > 0 && <PungoLoader />}
    </div>
  );
};

export default Navbar;
