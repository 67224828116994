import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "app/hooks";
import { useTranslation } from "react-i18next";
import PungoButton from "pungo-ui/PungoButton";
import PungoMenu from "pungo-ui/PungoMenu";
import { isAuthorized } from "utils/auth";
import { logOutSession } from "store/actions/authActions";
import { fetchProfileAndPlanInfo } from "store/actions/profileActions";
import { selectAuthUser, selectUserName, selectUserRole} from "store/slices/auth";
import { AccountCircle, People, Person, Logout } from "@mui/icons-material";
import { Divider, ListItemIcon, MenuItem } from "@mui/material";
import colors from "../../styles/export.module.scss";
import styles from "./index.module.scss";

const PungoLoginCircle: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLogged = useSelector(selectUserName);
  const role = useSelector(selectUserRole);
  const userInfo = useSelector(selectAuthUser);
  const isAuth = isAuthorized("userManagement", role[0]);
  const { t } = useTranslation();

  const onClickProfile = () => {
    dispatch(fetchProfileAndPlanInfo());
    navigate("/admin-profile");
  };

  const handleOnAdmin = () => {
    navigate("/admin");
  };

  const handleOnLogOut = () => {
    if (userInfo) {
      dispatch(logOutSession({ id_token: userInfo.id_token }));
    }
  };

  const getUserAction = () => {
    if (!isLogged || !userInfo) {
      return (
        <PungoButton
          onClick={() => navigate("/login")}
          type="icon"
          icon={
            <AccountCircle
              fontSize="large"
              style={{
                color: `${colors.gray8} `,
              }}
            />
          }
        />
      );
    } else {
      return (
        <PungoMenu
          content={contentForMenu}
          icon={
            <AccountCircle
              fontSize="large"
              style={{
                color: `${colors.blue3}`,
              }}
            />
          }
        />
      );
    }
  };

  const contentForMenu = (
    <div>
      <MenuItem onClick={onClickProfile}>
        <ListItemIcon>
          <Person fontSize="small" />
        </ListItemIcon>
        {t("userLabel")}
      </MenuItem>
      {!isAuth.hidden && (
        <MenuItem onClick={handleOnAdmin}>
          <ListItemIcon>
            <People fontSize="small" />
          </ListItemIcon>
          {t("adminLabel")}
        </MenuItem>
      )}
      <Divider />
      <MenuItem onClick={handleOnLogOut}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        {t("logOutLabel")}
      </MenuItem>
    </div>
  );

  return (
    <div className={styles.PungoLoginCircle}>
      {getUserAction()}
    </div>
  );
};

export default PungoLoginCircle;
