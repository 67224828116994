import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from "chart.js";

import PungoSectionCard from "pungo-ui/PungoSectionCard";
import SelectableTabs from "./common/SelectableTabs";
import PungoDemoAccordion from "pungo-ui/PungoDemoAccordion";
import PungoDemoResultsCard from "pungo-ui/PungoDemoResultsCard";
import cost from "assets/AucaImages/Cost.svg";
import optimizedCost from "assets/AucaImages/OptimizedCost.svg";
import carbonEmissions from "assets/AucaImages/carbonEmissions.svg";
import reducedCarbonEmissions from "assets/AucaImages/reducedCarbonEmissions.svg";
import {
  SummarySection,
  IndicatorSection,
  ActionSection,
  ContributionSection,
} from "./ResultSections";
import { selectProPlanResults } from "store/slices/plan";

import colors from "styles/export.module.scss";
import styles from "./index.module.scss";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import ResultsPdf from "./ResultsPdf";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip);

const ResultsProSection: React.FC = () => {
  const { t } = useTranslation();
  const proPlanResults = useSelector(selectProPlanResults);

  const optimizationResults = proPlanResults?.optimization;
  const [
    optimizationResultsSelectedTabIndex,
    setOptimizationResultsSelectedTabIndex,
  ] = useState<number>(0);

  const getOptimizationResults = () => (
    <div id="refProOptimization">
      <div className={styles.subTitle}>
        {t("DemoPage.OptimizationSection.noteToTitlePro")}
      </div>
      <SelectableTabs
        labels={[t("proPlan.tabs.emissions"), t("proPlan.tabs.costs")]}
        onSelect={setOptimizationResultsSelectedTabIndex}
        selectedIndex={optimizationResultsSelectedTabIndex}
      />
      {optimizationResultsSelectedTabIndex === 1 ? (
        <div className={styles.results}>
          <PungoDemoResultsCard
            description={t("DemoPage.OptimizationSection.currentCosts")}
            result={`${optimizationResults.costCurrent.toFixed(2)} USD`}
            color={colors.red1}
            image={cost}
          />
          <PungoDemoResultsCard
            description={t("DemoPage.OptimizationSection.optimizedCosts")}
            result={`${optimizationResults.costOptimized.toFixed(2)} USD`}
            color={colors.green1}
            image={optimizedCost}
            reducedCost={`${optimizationResults.costReduced.toFixed(2)} USD`}
          />
        </div>
      ) : (
        <div className={styles.results}>
          <PungoDemoResultsCard
            description={t("proPlan.resultLabel.emissionWithNoOptimization")}
            result={`${optimizationResults.emissionWithOutOptimization.toFixed(
              2
            )} kg CO2-eq`}
            color={colors.red1}
            image={carbonEmissions}
          />

          <PungoDemoResultsCard
            description={t("proPlan.resultLabel.emissionWithOptimization")}
            result={`${optimizationResults.emissionOptimized.toFixed(
              2
            )} kg CO2-eq`}
            color={colors.green1}
            image={reducedCarbonEmissions}
            reducedEmissions={`${optimizationResults.emissionReduced.toFixed(
              2
            )} kg CO2-eq`}
          />
        </div>
      )}
    </div>
  );

  return (
    <PungoSectionCard title={t("DemoPage.ResultsSection.results")} id="refProResults">
      <div className={styles.container}>
        <div className={styles.resultsContainer} id="refProEmissions">
          <SummarySection />
        </div>
        <div className={styles.resultsContainer}>
          <IndicatorSection />
        </div>
        <div className={styles.resultsContainer}>
          <ContributionSection />
        </div>
        {/* <div className={styles.resultsContainer}>
          <PredictionSection />
        </div>
       */}
        <div className={styles.resultsContainer}>
          <ActionSection />
        </div>
        <div className={styles.resultsContainer}>
          <PungoDemoAccordion
            title={t("proPlan.labels.optimization")}
            isPro
            content={getOptimizationResults()}
          />
        </div>
        <div id="refProDownload">
          <ResultsPdf />
        </div>
      </div>
    </PungoSectionCard>
  );
};

export default ResultsProSection;
