import React, {useEffect} from "react";
import FooterOnePageSection from "components/AucaComponents/FooterOnePageSection";
import FAQSection from "components/AucaPage/sections/FAQSection";
import ContactUsSection from "components/AucaPage/sections/ContactUsSection";
import styles from "./index.module.scss";

const FAQPage: React.FC = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className={styles.FAQPage}>
        <FAQSection />
        <ContactUsSection />
        <FooterOnePageSection />
      </div>
  );
};

export default FAQPage;
