import React, { useState } from "react";
import styles from "./index.module.scss";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { makeStyles } from "@material-ui/core/styles";
interface PungoAccordionProps {
  title: string;
  content?: React.ReactNode;
  noborder?: boolean;
  expan?: boolean;
}

const useStyles = makeStyles({
  content: {
    justifyContent: "center",
  },
});
const PungoAccordion: React.FC<PungoAccordionProps> = (props) => {
  const { title, content, noborder = false, expan = false } = props;

  const [expanded, setExpanded] = useState(expan);
  const classes = useStyles();

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={() => handleChange()}
      elevation={0}
      sx={{
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" classes={{ content: classes.content }}>
        <div className={noborder === false ? styles.titleContainer : styles.titleContainerNoBorder}>
          <div className={styles.accordionTitle}>{title}</div>
          <div className={styles.icon}>{expanded ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />}</div>
        </div>
      </AccordionSummary>
      <AccordionDetails> {content}</AccordionDetails>
      {noborder && <div className={styles.line}></div>}
      
    </Accordion>
  );
};

export default PungoAccordion;
