import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

import monitor from "assets/PungoIcons/MonitorAnimation.svg";

import styles from "./index.module.scss";

const AnimationSection: React.FC = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;

  return (
    <section className={styles.container} id="start">
      <div className={styles.text}>
        {language === "es" ? (
          <p className={styles.header}>
            Plataforma digital de gestión de&nbsp;
            <span className={styles.accents}>energía</span> y&nbsp;
            <span className={styles.accents}>emisiones</span> de carbono en la
            industria
          </p>
        ) : (
          <p className={styles.header}>
            Digital platform for <span className={styles.accents}>energy</span>
            &nbsp; and carbon <span className={styles.accents}>emissions</span>
            &nbsp; management in the industry
          </p>
        )}
        {t("animationSection.body")}
      </div>
      <img className={styles.gif} src={monitor} alt="Monitor animation" />
    </section>
  );
};

export default AnimationSection;
