import { useMediaQuery } from "@mui/material";
import json2mq from "json2mq";
import React from "react";
import { useTranslation } from "react-i18next";

import carbonFootprint from "../../../assets/AucaImages/carbonFootprint.svg";
import carbonFootprintSmall from "../../../assets/AucaImages/carbonFootprintSmall.svg";
import breakpoints from "../../../styles/export.module.scss";

import styles from "./index.module.scss";

const CarbonFootprintSection: React.FC = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const desktopView = useMediaQuery(
    json2mq({
      minWidth: breakpoints.lg,
    })
  );
  return (
    <section className={styles.container} id="start">
      <div className={styles.content}>
        <img
          className={styles.image}
          src={desktopView ? carbonFootprint : carbonFootprintSmall}
          alt="Carbon Footprint"
        />
        {!desktopView && (
          <div className={styles.legend}>
            {language === "es" ? (
              <ol>
                <li>Electricidad adquirida para uso propio</li>
                <li>Combustión Fósil</li>
                <li>Vehículos propiedad de la empresa</li>
                <li>Producción de materiales adquiridos</li>
                <li>Uso de Productos</li>
                <li>Actividades Adquiridas</li>
                <li>Vehículos propios de contratistas</li>
                <li>Disposición de residuos</li>
                <li>Viajes de Negocios de Empleados</li>
              </ol>
            ) : (
              <ol>
                <li>Purchased electricity for own use</li>
                <li>Fossil Combustion</li>
                <li>Company owned vehicles</li>
                <li>Production of purchased materials</li>
                <li>Use of Products</li>
                <li>Acquired Activities</li>
                <li>Contractors own vehicles</li>
                <li>Waste disposal</li>
                <li>Employee Business Trips</li>
              </ol>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default CarbonFootprintSection;
