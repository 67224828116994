import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CalculationOnline from "components/CalculationOnline";
import SideBarMenu from "components/AucaComponents/SidebarMenu";
import { selectVerticalMenuExpand } from "store/slices/verticalMenuExpand";
import { SideBarElementType } from "components/AucaComponents/SidebarMenu";
import useMediaQuery from "@mui/material/useMediaQuery";
import json2mq from "json2mq";
import breakpoints from "styles/export.module.scss";
import { Folder, PhotoCamera, Speed, Savings, Dataset } from "@mui/icons-material";
import styles from "./index.module.scss";

const CalculationOnlineMenu: React.FC = () => {
  const expand = useSelector(selectVerticalMenuExpand);
  const { t } = useTranslation();

  // separator line order is any number, not used
  const menu: SideBarElementType[] = [
    { order: 999, name: "", id: "", icon: "", type: "spacer" },
    { order: 999, name: "", id: "", icon: "", type: "spacer" },
    { order: 0, name: t("DemoPage.DataInputSection.dataInput"), id: "refDataInput", icon: <Dataset sx={{ fontSize: 37, color: "gray" }} />, type: "listElement" },
    { order: 999, name: "", id: "", icon: "", type: "line" },
    { order: 1, name: t("DemoPage.ResultsSection.reference.result"), id: "refDataInput", icon: <Folder sx={{ fontSize: 31, color: "gray" }} />, type: "title" },
    { order: 3, name: t("DemoPage.ResultsSection.reference.emission"), id: "refResultsEmissions", icon: <PhotoCamera sx={{ fontSize: 37, color: "gray" }} />, type: "listElement" },
    { order: 4, name: t("DemoPage.ResultsSection.reference.indicator"), id: "refResultsIndicators", icon: <Speed sx={{ fontSize: 37, color: "gray" }} />, type: "listElement" },
    { order: 5, name: t("DemoPage.ResultsSection.reference.optimization"), id: "refResultsOptimizations", icon: <Savings sx={{ fontSize: 37, color: "gray" }} />, type: "listElement" },
    { order: 999, name: "", id: "", icon: "", type: "line" },
  ];

  const mobileView = useMediaQuery(
    json2mq({
      minWidth: breakpoints.xlg,
    })
  );

  const getPage = () => {
    return <CalculationOnline />;
  };

  return (
    <div className={styles.CalculationOnlineMenu}>
      {mobileView && (
        <div className={styles.sideBar} style={{ width: expand ? "9vw" : "2vw" }}>
          <SideBarMenu menuData={menu} inPageMenu={true} />
        </div>
      )}
      <div className={styles.panel} style={{ width: expand ? "91vw" : "96vw" }}>
        <div className={styles.marginTop} style={{ marginTop: mobileView ? "30px" : "120px" }}>
          {getPage()}
        </div>
      </div>
    </div>
  );
};

export default CalculationOnlineMenu;
