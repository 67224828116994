import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

interface Position {
  currentState: boolean;
}

const initialState: Position  = {
  currentState: true,
}

export const verticalMenuExpandSlice = createSlice({
  name: 'verticalSelection',
  initialState,
  reducers: {
    saveVerticalMenuExpand: (state, action: PayloadAction<boolean>) => {
      state.currentState = action.payload;
    },
  },
})

export const { saveVerticalMenuExpand } = verticalMenuExpandSlice.actions
export const selectVerticalMenuExpand = (state: RootState) => state.verticalMenuExpandManagement.currentState
export default verticalMenuExpandSlice.reducer